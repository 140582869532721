<template>
  <div class="imgys">
    <div class="top">
      <div class="topItem">
        <span :class="['itemText', xzActive === 0 ? 'activeText' : '']"
          >压缩等级</span
        >
        <el-select
          @change="djActive"
          @focus="xzActive = 0"
          v-model="djvalue"
          placeholder="请选择"
        >
          <el-option
            v-for="item in dj"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="topItem">
        <span :class="['itemText', xzActive === 1 ? 'activeText' : '']"
          >图片尺寸</span
        >
        <el-select @focus="xzActive = 1" v-model="ccvalue" placeholder="请选择">
          <el-option
            v-for="item in cc"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="topItem zddx">
        <span :class="['itemText', xzActive === 2 ? 'activeText' : '']"
          >指定大小</span
        >
        <el-tooltip
          effect="dark"
          content="实际结果和希望值会存在一定偏差"
          placement="top-start"
        >
          <div>
            <el-input-number
              v-model="sizeValue"
              controls-position="right"
              @change="sizeChange"
              @focus="xzActive = 2"
              :min="1"
              :max="51200"
            ></el-input-number>
          </div>
        </el-tooltip>
        <div class="kb">KB</div>
      </div>
    </div>

    <div class="nr" v-show="uploadListArr.length === 0">
      <!-- animate__animated animate__pulse animate__infinite	infinite animate__slow -->
      <img
        class="zsimg animate__animated animate__pulse animate__infinite infinite animate__slow"
        src="@/assets/compressym/imgys.png"
        alt=""
      />
      <h2>图片压缩</h2>
      <span class="xbt">支持多种图片格式，不改变图片尺寸，也能压缩</span>
      <div class="uploadContent">
        <!--  :before-upload="globalBeforeUpload" -->
        <!--  -->

        <el-upload
          :on-exceed="() => $tips(`最多只能上传12个文件，请重新选择。`)"
          :limit="12"
          ref="upload"
          :accept="'.jpg,.jpeg,.png,.webp'"
          :show-file-list="false"
          :before-upload="BeforeUpload"
          :data="fileType"
          drag
          action="https://file.upload.sanwubeixin.cn/api/upload/uploadFile"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError"
        >
          <div class="uploadSlot" @click.stop="uploadBeforeBtn">
            <div class="scBtn">
              <img
                class="imgicon"
                src="@/assets/compressym/scicon.png"
                alt=""
              />
              <span>点击或拖拽上传图片</span>
            </div>
            <div class="Tips">
              支持JPG、JPEFG、PNG、BMP、WEBP等图片格式，单次可同时处理12张
            </div>
          </div>
        </el-upload>
      </div>
    </div>

    <div class="Yupload" v-show="uploadListArr.length !== 0">
      <div class="yuploadTitle">
        <span>处理列表</span>
        <div class="cxys" @click="anew">
          <img src="@/assets/cx.png" alt="" />
          <span>重新压缩</span>
        </div>
      </div>
      <!-- 列表 -->
      <div class="lbItemrq">
        <div class="lbItem" v-for="(item, index) in uploadListArr" :key="index">
          <img class="itemimg" :src="item.url" alt="" />
          <div class="myx">
            <span>名称</span>
            <span>{{ item.name }}</span>
          </div>

          <div class="myx">
            <span>格式</span>
            <span
              >{{ item.imgwidth + "*" + item.imgheight }}{{ item.format }}</span
            >
          </div>

          <div class="myx">
            <span>原图</span>
            <span>{{ item.lifesize }}</span>
          </div>

          <div class="myx">
            <span>压缩后</span>
            <span style="color: #ff6a41" v-if="item.isBtn">
              {{ item.aftersize }}</span
            >
            <el-progress
              v-else
              :show-text="false"
              :percentage="item.isschedule"
              color="#f96d46"
            ></el-progress>
          </div>

          <div style="width: 0">
            <a
              download="Snipaste_2024-07-02_14-36-37"
              :href="item.aHref"
              class="XzBtn"
              v-if="item.isBtn"
              >下载</a
            >
            <div class="js" v-else>加速处理中...</div>
          </div>

          <div class="gbItem">
            <img @click="gbItemBtn(item)" src="@/assets/gb.png" alt="" />
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="bottomL">
          <div class="bottomItem add" @click="addImgBtn">
            <img src="@/assets/jia.png" alt="" />
            <span>继续添加</span>
          </div>

          <div class="tx12">单次可同时处理12张</div>
        </div>

        <div class="bottomR">
          <div class="bottomItem delete" @click="dialogVisible = true">
            <img src="@/assets/delete.png" alt="" />
            <span>一键清空</span>
          </div>

          <el-tooltip
            class="item"
            effect="dark"
            content="以ZIP格式打包后下载"
            placement="top-start"
          >
            <div class="bottomItem xz" @click="AllZipBtn">
              <img src="@/assets/xz.png" alt="" />
              <span>全部下载</span>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <!-- 清空弹出层 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      top="10%"
      width="30%"
    >
      <div class="qktcc">
        <h2 style="color: #000000">温馨提示</h2>
        <div style="color: #000000">即将清空所有内容，请确认</div>
        <div class="twoBtn">
          <div class="btn c" @click="btnC">点错了</div>
          <div class="btn q" @click="btnQ">确认</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downloadAndZipFiles } from "@/utils/commonmethod";
import { getspQuest } from "@/api/upload";
import assets from "@/utils/commonality";

export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dj: [
        {
          value: "90",
          label: "90%",
        },
        {
          value: "80",
          label: "80%",
        },
        {
          value: "70",
          label: "70%(推荐)",
        },
        {
          value: "60",
          label: "60%",
        },
        {
          value: "50",
          label: "50%",
        },
        {
          value: "40",
          label: "40%",
        },
        {
          value: "30",
          label: "30%",
        },
        {
          value: "20",
          label: "20%",
        },
        {
          value: "10",
          label: "10%",
        },
      ],
      djvalue: "70",
      // 尺寸
      cc: [
        {
          value: "1024,733",
          label: "原始尺寸",
        },
        {
          value: "1024,732",
          label: "电脑尺寸",
        },
        {
          value: "480,343",
          label: "手机尺寸",
        },
        {
          value: "295,413",
          label: "1寸照",
        },
      ],
      ccvalue: "1024,732",
      sizeValue: "",
      xzActive: 0,
      isUpload: true, //是否上传文件
      dialogVisible: false, //清空弹出层
      fileType: {
        is_cache: 1,
      },
      uploadListArr: [],
      getdocumentUrl: "", //上传文件后返回的url地址
      proceed: false, //上传进行中
      polling: null, //中止轮询
      pollingInstances: {
        //每一条数据单独的轮询标识
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    convertSize(sizeInKB) {
      let sizeInMB = sizeInKB / 1024;
      let sizeInGB = sizeInMB / 1024;

      if (sizeInKB > 1024 * 1024) {
        return `${sizeInGB.toFixed(2)} GB`;
      } else if (sizeInKB > 1024) {
        return `${sizeInMB.toFixed(2)} MB`;
      } else {
        return `${sizeInKB} KB`;
      }
    },
    djActive(e) {
      this.uploadListArr.forEach((item) => {
        let newarr = assets.formatFileSize(item.yuanchicun).split(" ");
        item.aftersize =
          ((newarr[0] * parseFloat(e)) / 100).toFixed(2) + newarr[1];
      });
    },

       delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
    async anew() {
    for (let item of this.uploadListArr) {
      item.isBtn = false;
      item.isschedule = 0;
      await this.uploarApi(item.url);
      await this.delay(1000);
    }
  },
    sizeChange(e) {
      console.log(e);
      this.xzActive = 2;
      this.uploadListArr.forEach((item) => {
        item.aftersize = assets.formatFileSize(e * 1000);
      });
    },
    btnC() {
      //清空弹出层取消
      this.dialogVisible = false;
    },
    btnQ(item) {
      //清空弹出层确认
      this.dialogVisible = false;
      this.uploadListArr = [];
      this.cancelPolling(); //关闭轮询
    },
    handleUploadSuccess(response, flie, fileList) {
      //上传成功
      const img = new Image();
      img.onload = () => {
        // 图片加载完成后获取宽度和高度
        const width = img.width;
        const height = img.height;
        this.uploadListArr.push({
          uid: flie.uid,
          name: flie.name,
          format: flie.raw.type.split("/")[1],
          lifesize: assets.formatFileSize(flie.size),
          aftersize: flie.size, //压缩后的数据大小
          isBtn: false, //控制btn的
          aHref: "", //下载链接
          url: response.data.url,
          isschedule: 0, //控制进度条
          imgwidth: width, //图片的宽度
          imgheight: height, //图this.uploadListArr片的高度
          yuanchicun: flie.size,
        });

        this.uploarApi(response.data.url);
        this.$tips("文件上传成功！");
        this.$store.dispatch("startLoading", false);
      };
      img.src = response.data.url; // 设置图片的 URL，触发加载
      // 在这里可以处理服务器返回的数据，比如保存文件URL等
    },
    handleUploadError(err, file, fileList) {
      //上传失败
      this.$store.dispatch("startLoading", false);
      console.error("上传失败:", err);
      this.$tips("文件上传失败，请重试。");
      // 在这里可以处理上传失败的情况，比如显示错误信息等
    },
    BeforeUpload(flie) {
      const isImage = /\.(jpg|jpeg|png|webp)$/.test(flie.name.toLowerCase());
      if (!isImage) {
        this.$tips("请选择 JPG/JPEG、PNG 或 WebP 格式的图片文件");
        return isImage;
      }
      if (this.proceed) {
        // true 就是禁止  false 就是可以
        this.$tips("正在处理文件中，请稍后。");
        return false;
      }
      // //上传前的钩子
      if (this.$store.getters.isuserInfo.isSign) {
        //如果登录了就判断有没有开通会员
        if (!this.$store.state.userInfo.isVip) {
          this.$store.commit("updatispopvip", true);
          return false;
        }

        this.$store.dispatch("startLoading", true);
        return true;
      } else {
        //先判断有没有登录  没有登录让登录
        //如果登录了判断有没有会员
        this.$tips("请先登录后再上传文件！");
        this.$store.commit("updateloginVisible", true);
        return false;
      }
    },
    async uploarApi(url, retries = 3) {
      let newsign = await this.getNewsign();

      try {
        let res = await getspQuest(
          "/api/image/pictureCompressCreate",
          "post",
          "16",
          {
            quality: this.xzActive === 0 ? this.djvalue : "",
            kbyte: this.xzActive === 2 ? this.sizeValue : "",
            width: this.xzActive === 1 ? this.ccvalue.split(",")[0] : "",
            height: this.xzActive === 1 ? this.ccvalue.split(",")[1] : "",
            format: this.uploadListArr
              .find((item) => item.url === url)
              .name.slice(
                ((this.uploadListArr
                  .find((item) => item.url === url)
                  .name.lastIndexOf(".") -
                  1) >>>
                  0) +
                  2
              ),
            url: url,
            percentage: this.djvalue,
            sign: newsign,
          }
        );
        if (res.code === 200) {
          this.getsucesUrl(res.data.task_id, url);
        }
      } catch (error) {
        if (retries > 0) {
          console.warn(`Retrying upload for ${url}, attempts left: ${retries}`);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // 1秒后重试
          await this.uploarApi(url, retries - 1);
        } else {
          console.error(`Failed to upload ${url} after multiple attempts.`);
        }
      }
    },

    getsucesUrl(id, url) {
      this.proceed = true; // 正在上传
      let retryCount = 0;
      const maxRetries = 300; // 最大重试次数

      if (!this.pollingInstances) {
        this.pollingInstances = {};
      }

      const pollingId = `${id}_${url}`;

      if (this.pollingInstances[pollingId]) {
        clearInterval(this.pollingInstances[pollingId]);
        delete this.pollingInstances[pollingId];
      }

      this.pollingInstances[pollingId] = setInterval(async () => {
        let res = await getspQuest(
          "/api/image/pictureCompressGet",
          "get",
          "16",
          {
            task_id: id,
          }
        );

        if (res.code === 200 && res.data.status === 1) {
          clearInterval(this.pollingInstances[pollingId]);
          delete this.pollingInstances[pollingId];
          this.proceed = false;

          this.uploadListArr.forEach((item) => {
            if (item.url === url) {
              item.isschedule = 100;
              item.isBtn = true;
              item.aHref = res.data.result;
              // item.aftersize = item.aftersize
              if (this.xzActive === 0) {
                item.aftersize = assets.formatFileSize(
                  (item.yuanchicun * parseFloat(this.djvalue)) / 100
                );
              } else if (this.xzActive === 2) {
                item.aftersize = this.convertSize(this.sizeValue);
              }
            }
          });
        } else {
          retryCount++;
          this.uploadListArr.forEach((item) => {
            if (item.url === url) {
              if (item.isschedule >= 90) {
                item.isschedule = 90;
              } else {
                item.isschedule += 10;
              }
            }
          });

          if (retryCount >= maxRetries) {
            this.proceed = false;
            clearInterval(this.pollingInstances[pollingId]);
            delete this.pollingInstances[pollingId];
            this.$tips("请求超时，请稍后重试");
          }
        }
      }, 1000);
    },

    cancelPolling(url) {
      if (this.pollingInstances) {
        if (url) {
          for (const [key, intervalId] of Object.entries(
            this.pollingInstances
          )) {
            if (key.endsWith(`_${url}`)) {
              clearInterval(intervalId);
              delete this.pollingInstances[key];
              this.proceed = false;
              // this.$tips(`轮询已停止：URL ${url}`);
              return;
            }
          }
          // this.$tips(`未找到与URL ${url} 对应的轮询实例`);
        } else {
          for (const [key, intervalId] of Object.entries(
            this.pollingInstances
          )) {
            clearInterval(intervalId);
            delete this.pollingInstances[key];
          }
          this.proceed = false;
          // this.$tips(`所有轮询已停止`);
        }
      } else {
        // this.$tips(`没有任何轮询实例`);
      }
    },

    async AllZipBtn() {
      //下载zip
      // 初始化一个空数组，用于存储所有的 aHref
      let allarr = [];

      // 遍历 this.uploadListArr 数组
      this.uploadListArr.forEach((item) => {
        // 检查对象是否有 aHref 属性并且不为空
        if (item.aHref) {
          allarr.push(item.aHref);
        }
      });

      if (allarr.length === 0) {
        this.$tips("暂无文件可下载，请确认文件是否处理成功");
      } else {
        // 调用封装好的下载方法
        this.$store.dispatch("startLoading", true);
        this.$tips("正在打包zip中，请稍等");
        // 调用封装好的下载方法
        try {
          await downloadAndZipFiles(allarr, "all_files.zip");
          this.$store.dispatch("startLoading", false);
        } catch (error) {
          this.$tips("打包失败，请重试");
          this.$store.dispatch("startLoading", false);
        }
      }
    }, 
    gbItemBtn(item) {
      this.cancelPolling(item.url);
      this.uploadListArr = this.uploadListArr.filter((i) => i.url !== item.url);
    },
    addImgBtn() {
      //继续添加
      this.$refs.upload.$children[0].$refs.input.click();
    },
  },
};
</script>

<style scoped lang="scss">
.imgys {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    width: 100%;
    height: 13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topItem {
      width: 32%;
      height: 100%;
      background-color: #fff;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .itemText {
        font-size: 14px;
        margin-right: 10px;
        white-space: nowrap;
      }
      .activeText {
        color: #ff6840;
      }
    }
  }
  .kb {
    border: 1px solid #dcdfe6;
    width: 50px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f4f6;
    border-radius: 0 8px 8px 0;
    color: #555555;
  }
   ::v-deep .el-input__inner {
    height: 50px !important;
    border-radius: 8px !important;
  }

.zddx {
    ::v-deep .el-input__inner {
    height: 50px !important;
    border-radius: 8px 0 0 8px !important;
  }
  ::v-deep .el-input-number__decrease {
    right: 7px !important;
    width: 18px !important;
    height: 50% !important;
    border-radius: 0 !important;
    border-right: 1px solid #dcdfe6 !important;
    .el-icon-arrow-down:before {
      // 三角形
      content: "";
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 6px 4px 6px 4px;
      border-color: #5b5b5b rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      transform: translateY(5px);
    }
  }
  ::v-deep .el-input-number__increase {
    right: 7px !important;
    width: 18px !important;
    height: 50% !important;
    border-radius: 0 !important;
    border-right: 1px solid #dcdfe6 !important;
    .el-icon-arrow-up:before {
      // 三角形
      content: "";
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 6px 4px 6px 4px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #5b5b5b rgba(0, 0, 0, 0);
      transform: translateY(5px);
    }
  }
}

  .Yupload {
    position: relative;
    width: 100%;
    height: 84%;
    background-color: #fff;
    border-radius: 18px;
    padding: 20px 30px;
    box-sizing: border-box;
    .yuploadTitle {
      display: flex;
      justify-content: space-between;
      .cxys {
        padding: 8px 15px;
        background-color: #f2f4f4;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }

    .lbItem {
      width: 100%;
      height: 119px;
      border: 1px solid #f4f4f4;
      background-color: #fafcfe;
      border-radius: 8px;
      margin-top: 15px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .itemimg {
        width: 99px;
        height: 99px;
        display: block;
      }

      .myx {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & span:nth-child(1) {
          color: #999999;
        }
      }

      .XzBtn {
        width: 70px;
        height: 37px;
        border: 1px solid #ff7a46;
        border-radius: 10px;
        text-align: center;
        line-height: 37px;
        color: #ff7a46;
        cursor: pointer;
        white-space: nowrap;
        padding: 7px 10px;
      }
      .js {
        font-size: 14px;
        white-space: nowrap;
      }

      .gbItem {
        height: 100%;
        width: 28px;
        img {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }

    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background-color: #fff;
      box-shadow: 0px -7px 10px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      padding: 0 15px;
      justify-content: space-between;
      box-sizing: border-box;
      .bottomL,
      .bottomR {
        display: flex;
        align-items: center;
        .tx12 {
          font-size: 14px;
          color: #999999;
        }
      }
      .add {
        border: 1px dashed #ff6840;
        color: #ff6840;
        background-color: #ffefeb;
        margin-right: 20px;
      }
      .delete {
        background-color: #f2f4f6;
        margin-right: 15px;
      }
      .xz {
        background: linear-gradient(135deg, #ff6640 0%, #ff7a41 100%);
        color: #fff;
      }
      .bottomItem {
        padding: 8px 14px;
        border-radius: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
  .nr {
    width: 100%;
    height: 84%;
    background-color: #fff;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .zsimg {
      width: 241px;
      height: 163px;
    }
    h2 {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .xbt {
      color: #a7a7a7;
      font-size: 14px;
    }

    .uploadContent {
      width: 80%;
      margin-top: 25px;
      ::v-deep .el-upload {
        width: 100% !important;
        margin: 0 auto;
      }
      ::v-deep .el-upload-dragger {
        width: 100% !important;
        border: 1px dashed #ff6840;
        border-radius: 18px !important;
      }

      .uploadSlot {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 239, 235, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .Tips {
          color: #6a6a69;
          margin-top: 30px;
        }
        .scBtn {
          width: 294px;
          height: 52px;
          background-color: #ff6840;
          border-radius: 10px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .imgicon {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.qktcc {
  background: url("@/assets/qkbg.png") no-repeat;
  background-size: 100% 100%;
  width: 466px;
  height: 252px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .twoBtn {
    display: flex;
    align-items: center;
    .btn {
      width: 196px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      border-radius: 12px;
      margin: 0 10px;
      cursor: pointer;
    }
    .c {
      background-color: #fff9f4;
      color: #000;
    }
    .q {
      color: #fff;
      background: linear-gradient(135deg, #ff6640 0%, #ff7a41 100%);
    }
  }
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog {
  box-shadow: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
</style>
